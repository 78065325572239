export default function (editor) {
  return ({
    toggleMark: editor.toggleMark,
    toggleBlock: (block) => {
      // Handle the quote-block first thing
      if (block === 'quote-block') {
        // If we are in a quoteblock, unwrap everything and go back to a paragraph
        if (editor.isQuoteBlock()) {
          return editor.unwrapAll()
        }

        editor.unwrapAll()
        return editor.wrapBlock(block)
      }

      if (editor.isQuoteBlock()) {
        return null
      }

      if ((block === 'list-ordered' || block === 'list-unordered')) {
        if (!editor.isList()) {
          editor.unwrapAll()
          editor.wrapBlock(block)
          editor.setBlocks('list-item')
          return editor.mergeLists()
        }

        return editor.toggleList(block)
      }

      if (block === 'image') {
        if (editor.isImage()) {
          return editor.unwrapAll()
        }
        return editor.insertImage()
      }

      if (!editor.isBlockActive(block)) {
        editor.unwrapAll()
      }
      return editor.setBlocks(editor.isBlockActive(block) ? 'paragraph' : block)
    },
    toggleInline: (inline, data) => {
      if (editor.isBlockActive(inline)) {
        editor.unwrapInline(inline)
      } else {
        editor.wrapInline({ type: inline, data })
      }
    },
    toggleColor: (color) => {
      ['pink', 'orange', 'blue', 'green', 'purple']
        .filter((c) => c !== color)
        .forEach(editor.removeMark)

      editor.toggleMark(color)
    },
    isMarkActive: (mark) => editor.value.activeMarks.some((m) => m.type === mark),
    isBlockActive: editor.isBlockActive,
    isInlineActive: editor.isBlockActive,
    isInlineAvailable: (inline) => editor.value.selection.isExpanded || editor.isBlockActive(inline),
    getInlineData: (inline) => {
      const i = editor.value.fragment.findDescendant((node) => node.type === inline)

      return (i && i.data.toJSON()) || {}
    },
    setInlineData: (data) => {
      editor.setInlines({ data })
    },
  })
}
