import classNames from 'classnames-prefix'

export const block = 'insane-slate'
export const cx : any = classNames(block)

export const extractSlug = (n) => {
  const lines = []

  const extractLeaves = (document) => {
    if (document.nodes) {
      document.nodes.forEach((node) => {
        if (node.object === 'text') {
          if (node.leaves) {
            node.leaves.forEach((leaf) => {
              lines.push(leaf.text)
            })
            return
          }

          lines.push(node.text)
        }

        extractLeaves(node)
      })
    }
  }

  extractLeaves(n)

  return lines.join(' ')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .replace(/[^a-z0-9- ]/, '')
    .replace(/[^a-z0-9-]/g, ' ')
    .trim()
    .replace(/ /g, '-')
}

export const extractNodeKey = (n, i) => `${n.type}-${i}`
