// React
import React from 'react'
import { observer } from 'mobx-react'

// Utils
import { classNames } from 'utils/react'

// State
import state from 'state'

// Components
import ConnectedRouter from 'components/shared/routers/connected'
import Password from 'components/shared/password'
import { FaPowerOff } from 'components/shared/icons'
import { Logo } from 'components/shared/inline'
import { Prompt, Link } from 'components/shared/semantics'

// CSS
import 'style/style.scss'

import './connected.scss'


const block = 'layout-connected'
const cx = classNames(block)

// Patch Object.keys
const oldObjectKeys = Object.keys
Object.keys = (obj : any) => oldObjectKeys(obj || {})

const LayoutConnected = ({ children } : { children: React.ReactNode }) => (
  <ConnectedRouter>
    {state.session.account && !state.session.account.active ? (
      <Password />
    ) : (
      <div className={cx(block)}>
        <div className={cx('__top-bar')}>
          <Link to="/">
            <Logo className={cx('__logo')} />
          </Link>
          <FaPowerOff className={cx('__logout')} onClick={() => state.session.updateToken(null)} />
        </div>
        <div className={cx('__main')}>
          {children}
        </div>
      </div>
    )}

    <Prompt />
  </ConnectedRouter>
)

export default observer(LayoutConnected)
