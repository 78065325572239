export default function Heading() {
  return {
    onKeyDown(event, editor, next) {
      if (!editor.isBlockActive('heading-two') && !editor.isBlockActive('subheading')) {
        return next()
      }

      const isEnterKey = event.key === 'Enter'

      if (!isEnterKey) {
        return next()
      }

      // Enter key should make a paragraph instead of a new heading
      if (isEnterKey) {
        event.preventDefault()

        return editor.insertBlock('paragraph')
      }

      return next()
    },
  }
}
