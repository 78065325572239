import React from 'react'
import Helmet from 'react-helmet'

import { useStaticQuery, graphql } from 'gatsby'

const detailsQuery = graphql`
    query DefaultSEOQuery {
        site {
            siteMetadata {
                title
                description
                author
            }
        }
    }
`

function SEO({
  title,
  description = null,
  lang = 'fr',
  meta = [],
  keywords = [],
  type = 'website',
} : {
  title: string,
  description?: string|null,
  lang?: string,
  meta?: {name:string, content:string}[],
  keywords?: string[],
  type?: string
}) {
  const data = useStaticQuery(detailsQuery)
  const metaDescription = description || data.site.siteMetadata.description

  return (
    <Helmet
      defer={false}
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${data.site.siteMetadata.title}`}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: type || 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: data.site.siteMetadata.author,
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
      ]
        .concat(keywords.length > 0
          ? {
            name: 'keywords',
            content: keywords.join(', '),
          }
          : [])
        .concat(meta)}
    />
  )
}

export default SEO
