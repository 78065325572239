import React from 'react'
import Markdown from 'react-markdown'

import { cx } from '../utils'

// eslint-disable-next-line import/prefer-default-export
export const BlockImage = ({
  node, attributes, isFocused, isSelected, imagePicker: ImagePicker, editor,
  readOnly, locale,
} : BlockProps) => (readOnly ? (
  <div className={cx('__image')} {...attributes}>
    <img src={node.data.src} alt={node.data.caption?.[locale || 'en']} />

    <Markdown className={cx('__image-caption')}>{node.data.caption?.[locale || 'en']}</Markdown>
  </div>
) : (
  <div className={cx('__image', { '__image--focused': isFocused })} {...attributes}>
    { ImagePicker && <ImagePicker node={node} editor={editor} isFocused={isFocused || isSelected} locale={locale} /> }
  </div>
))
