import arrayFrom from 'array-from'

const getMetaTagName = (item) => item.getAttribute('name') || item.getAttribute('property')

const getMeta = (metaName) => {
  const metas = typeof document !== 'undefined' ? document.getElementsByTagName('meta') : []
  return arrayFrom(metas).find((item) => getMetaTagName(item) === metaName)
}

export const setMetaTags = (metaTagsList = {}) => {
  const { title, ...metaTags } = metaTagsList

  if (title && typeof document !== 'undefined') {
    document.title = title
  }

  Object.keys(metaTags).forEach((tagName) => {
    const currentTag = getMeta(tagName)

    if (!metaTags[tagName]) {
      // remove meta tags
      if (currentTag) {
        currentTag.parentNode.removeChild(currentTag)
      }

      return
    }

    if (currentTag) {
      // update a meta tag
      currentTag.setAttribute('content', metaTags[tagName])
    } else if (typeof document !== 'undefined') {
      // create a meta tag
      const meta = document.createElement('meta')
      meta.name = tagName
      meta.setAttribute('content', metaTags[tagName])
      document.getElementsByTagName('head')[0].appendChild(meta)
    }
  })
}

export const getDefaultMetaTags = (metaTags) => {
  if (metaTags) {
    return metaTags
  }

  const metas = typeof document !== 'undefined' ? document.getElementsByTagName('meta') : []

  return arrayFrom(metas).reduce(
    (acc, item) => ({ ...acc, [getMetaTagName(item)]: item.getAttribute('content') }),
    { title: typeof document !== 'undefined' ? document.title : '' },
  )
}
