import React, { useCallback } from 'react'
import { cx, extractSlug } from '../utils'


const BlockQuoteSizes = ['24', '21', '18']
const BlockQuotePlacements = [
  [{ left: '33%', top: '0' }, { left: '0', top: '50%' }, { bottom: '0', right: '10%' }],
  [{ left: '50%', bottom: '0' }, { right: '0', top: '10%' }, { left: '0', bottom: '20%' }],
  [{ left: '60%', top: '0' }, { right: '0', top: '10%' }, { bottom: '0', right: '20%' }],
  [{ left: '50%', bottom: '0' }, { right: '0', bottom: '10%' }, { left: '0', top: '20%' }],
  [{ right: '60%', top: '0' }, { right: '0', bottom: '10%' }, { bottom: '0', left: '20%' }],
]

export const BlockQuoteBlock = ({
  attributes, children, node, readOnly,
} : BlockProps) => {
  const l = readOnly ? extractSlug(node).length : node.key
  const placements = BlockQuotePlacements[l % BlockQuotePlacements.length]

  const makeSize = useCallback((i) => ({
    width: `${BlockQuoteSizes[(i + l) % BlockQuoteSizes.length]}px`,
    height: `${BlockQuoteSizes[(i + l) % BlockQuoteSizes.length]}px`,
  }), [l])

  return (
    <blockquote autoCorrect="false" spellCheck="false" className={cx('__quote-block')} {...attributes}>
      {children}

      {readOnly && (
        <div style={{ userSelect: 'none' }}>
          <div className={cx('__quote-block-sparkle')} style={placements[0]}>
            <div className={cx('__quote-block-sparkle--orange')} style={makeSize(0)} />
          </div>
          <div className={cx('__quote-block-sparkle')} style={placements[1]}>
            <div className={cx('__quote-block-sparkle--violet')} style={makeSize(1)} />
          </div>
          <div className={cx('__quote-block-sparkle')} style={placements[2]}>
            <div className={cx('__quote-block-sparkle--green')} style={makeSize(2)} />
          </div>
        </div>
      )}
    </blockquote>
  )
}

export const BlockQuoteAuthor = ({ attributes, children } : BlockProps) => (
  <p className={cx('__quote-author')} {...attributes}>
    {children}
  </p>
)
