export default function Image() {
  return {
    onBeforeInput(event, editor, next) {
      if (editor.isImage()) {
        const nextSibling = editor.value.document.getNextSibling(editor.getFocusInfo().currentNode.key)

        if (!nextSibling) {
          editor.insertBlock({ type: 'paragraph' })
        } else {
          editor.moveToStartOfNode(nextSibling)
        }
      }
      next()
    },
    onKeyDown(event, editor, next) {
      if (editor.isImage() && ['Enter', 'ArrowDown', 'ArrowRight'].includes(event.key)) {
        const nextSibling = editor.value.document.getNextSibling(editor.getFocusInfo().currentNode.key)

        if (!nextSibling) {
          editor.insertBlock({ type: 'paragraph' })
          return
        }
      }
      next()
    },

    commands: {
      insertImage(editor) {
        const focusInfo = editor.getFocusInfo()

        if (focusInfo.empty) {
          editor.setBlocks({
            type: 'image',
            data: { src: null, alt: null },
          })
        } else {
          editor.insertBlock({
            type: 'image',
            data: { src: null, alt: null },
          })
        }

        editor.insertBlock({
          type: 'paragraph',
        })
      },
      updateImageSrc(editor, src) {
        if (editor.isImage()) {
          editor.setBlocks({ src })
        }
      },
      updateImageAlt(editor, alt) {
        if (editor.isImage()) {
          editor.setBlocks({ alt })
        }
      },
    },
    queries: {
      isImage(editor) {
        return editor.isBlockActive('image')
      },
    },
  }
}
