import 'toastr/build/toastr.min.css'

export { default as moment } from 'moment'
export { default as queryString } from 'query-string'
export { default as axios } from 'axios'
export { default as toast } from 'toastr'


const _preventDefault : any = new Map()
export const preventDefault = (...args: any[]) => {
  if (args[0].target && args[0].preventDefault) {
    // If the first arg is an event, then preventDefault was passed
    // directly as an event callback. We simply prevent the default behavior
    args[0].preventDefault()

    return null
  } if (typeof args[0] === typeof Function) {
    // If the first arg is a function, then we memoize and curry it
    if (!_preventDefault.get(args[0])) {
      _preventDefault.set(args[0], (...fnArgs: any[]) => {
        preventDefault(...fnArgs)
        args[0](...fnArgs)
      })
    }

    // We then return the curried function
    return _preventDefault.get(args[0])
  }

  return null
}


const _stopPropagation : any = new Map()
export const stopPropagation = (...args: any[]) => {
  if (args[0].target && args[0].stopPropagation) {
    // If the first arg is an event, then preventDefault was passed
    // directly as an event callback. We simply prevent the default behavior
    args[0].stopPropagation()

    return null
  } if (typeof args[0] === typeof Function) {
    // If the first arg is a function, then we memoize and curry it
    if (!_stopPropagation.get(args[0])) {
      _stopPropagation.set(args[0], (...fnArgs: any[]) => {
        stopPropagation(...fnArgs)
        args[0](...fnArgs)
      })
    }

    // We then return the curried function
    return _stopPropagation.get(args[0])
  }

  return null
}
