import React from 'react'

import { cx } from '../utils'

export const BlockParagraph = ({ attributes, children } : BlockProps) => (
  <p autoCorrect="false" spellCheck="false" className={cx('__paragraph')} {...attributes}>
    {children}
  </p>
)

export const BlockHardParagraph = ({ attributes, children } : BlockProps) => (
  <p autoCorrect="false" spellCheck="false" className={cx('__paragraph', '__paragraph--hard')} {...attributes}>
    {children}
  </p>
)
