import { Document, Range } from 'slate'
import { getEventTransfer } from 'slate-react'

export default function Utils() {
  return ({
    queries: {
      getFocusInfo(editor) {
        // Take the node at current anchor
        const currentNode = editor.value.document.getLeafBlocksAtRange(Range.create({
          anchor: editor.value.selection.anchor,
          focus: editor.value.selection.anchor,
        })).get(0)

        const textIndex = editor.value.selection.anchor.path.last()

        // Check if we are at the start or end of the block
        const atStart = !textIndex && !editor.value.selection.anchor.offset
        const atEnd = (
          currentNode.getOffset([textIndex]) + editor.value.selection.anchor.offset
        ) === currentNode.text.length

        // Check if there is a next sibling to see if we are at the end of our parent
        const atParentEnd = !editor.value.document.getNextSibling(currentNode.key)
        // Check if there is a previous sibling to see if wa are at the *start* of our parent
        const atParentStart = !editor.value.document.getPreviousSibling(currentNode.key)

        const empty = atStart && atEnd
        const emptyParent = atParentStart && atParentEnd && empty

        return {
          currentNode,
          atStart,
          atEnd,
          atParentEnd,
          atParentStart,
          empty,
          emptyParent,
          collapsed: editor.value.selection.isCollapsed,
          range: editor.value.selection,
        }
      },

      isBlockActive(editor, block) {
        const { value } = editor

        if (!value) {
          return false
        }

        return !!value.fragment.findDescendant((node) => node.type === block)
      },

      getFamilies(editor) {
        // Regroup blocks into family trees
        return editor.value.document.getLeafBlocksAtRange(editor.value.selection)
          .map((node) => {
            const ancestors = editor.value.document.getAncestors(node.key)

            return ancestors.push(node)
          })
      },
    },

    commands: {
      unwrapAll(editor) {
        editor.setBlocks('paragraph')

        editor.value.document.getDescendantsAtRange(editor.value.selection)
          .reverse()
          .forEach((n) => {
            const parents = editor.value.document.getAncestors(n.key)
            const parent = parents.last()
            if (parent) {
              editor.unwrapBlock(parent.type)
            }
          })
      },
    },

    onPaste(event, editor, next) {
      // Get fragment as JSON
      const { fragment } = getEventTransfer(event)
      const data = fragment ? fragment.toJSON() : null

      if (data) {
        const document : { object: 'document', nodes: any } = {
          object: 'document',
          nodes: [],
        }

        const extractNodes = (node) => {
          if (!node || node.object === 'text') {
            return
          }

          if (node.nodes) {
            if (node.nodes[0].object === 'text') {
              document.nodes.push({
                object: 'block',
                type: 'paragraph',
                nodes: node.nodes,
              })
            } else {
              node.nodes.forEach(extractNodes)
            }
          }
        }

        extractNodes(data)

        return editor.insertFragment(Document.fromJSON(document))
      }

      return next()
    },
  })
}
