import { BlockHeading, BlockSubHeading } from './heading'
import { BlockHardParagraph, BlockParagraph } from './paragraph'
import { BlockQuoteAuthor, BlockQuoteBlock } from './block-quote'
import { BlockListItem, BlockListOrdered, BlockListUnordered } from './list'
import { BlockLink } from './link'
import { BlockImage } from './image'

const Blocks = {
  'heading-two': BlockHeading,
  'subheading': BlockSubHeading,

  'paragraph': BlockParagraph,
  'hard-paragraph': BlockHardParagraph,

  'quote-block': BlockQuoteBlock,
  'quote-author': BlockQuoteAuthor,

  'list-unordered': BlockListUnordered,
  'list-ordered': BlockListOrdered,
  'list-item': BlockListItem,

  'link': BlockLink,
  'image': BlockImage,
}

export default Blocks
