export default function QuoteBlock() {
  return {
    // eslint-disable-next-line consistent-return
    onKeyDown(event, editor, next) {
      if (!editor.isQuoteBlock()) {
        return next()
      }

      const isEnterKey = event.key === 'Enter'
      const isTabKey = event.key === 'Tab'
      const isBackspaceKey = event.key === 'Backspace'

      if (!isEnterKey && !isTabKey && !isBackspaceKey) {
        return next()
      }

      const {
        empty, atParentEnd, atParentStart, atStart,
      } = editor.getFocusInfo()

      // Enter key should unwrap quote-block after empty line
      if (isEnterKey) {
        if (editor.isQuoteAuthor()) {
          event.preventDefault()
          editor.moveToEndOfBlock()
          editor.insertBlock('paragraph')
          return editor.unwrapAll()
        }

        if (empty) {
          // If the line is currently empty, unwrap it
          return editor.unwrapAll()
        }

        // Else, proceed with new paragraph creation
        return next()
      }

      // If the tab key was pressed, and we are at the end of the quote-block, toggle author segment
      if (isTabKey) {
        event.preventDefault()

        if (atParentEnd && !atParentStart) {
          if (editor.isQuoteAuthor() && event.shiftKey) {
            return editor.setBlocks('paragraph')
          }

          if (!editor.isQuoteAuthor() && !event.shiftKey) {
            return editor.setBlocks('quote-author')
          }
        }
      }

      // Gracefully handle backspace, so that
      // - There is always one remaining paragraph if the is a quote-author
      // - If the quote-block is empty, it is destroyed
      if (isBackspaceKey) {
        // Get parent quote-block --- it can be anywhere in selection
        const blocks = editor.value.document.getDescendantsAtRange(editor.value.selection)
        const parent = blocks.find((n) => n.type === 'quote-block')
        const parentIsFocused = blocks.indexOf(parent) === 0

        if (!parentIsFocused || (atParentStart && atStart)) {
          const hasQuoteAuthor = parent.getBlocks().last().type === 'quote-author'

          if (hasQuoteAuthor) {
            editor.delete()
            const stillHasParent = editor.value.document.getChild(parent.key)

            if (!stillHasParent) {
              return null
            }

            editor.insertNodeByKey(parent.key, 0, { object: 'block', type: 'paragraph' })
            if (parentIsFocused) {
              editor.moveBackward()
              return next()
            }

            return null
          }

          editor.delete()
          return editor.unwrapAll()
        }
      }

      return next()
    },

    queries: {
      isQuoteBlock(editor) {
        return editor.isBlockActive('quote-block')
      },
      isQuoteAuthor(editor) {
        return editor.isBlockActive('quote-author')
      },
    },
  }
}
