import React from 'react'

import { cx } from '../utils'

// eslint-disable-next-line import/prefer-default-export
export const BlockUnknown = ({ attributes, children, node } : BlockProps) => (
  <div className={cx('__unknown')} {...attributes}>
    <pre>{JSON.stringify(node)}</pre>
    {children}
  </div>
)
