import React from 'react'
import { observer } from 'mobx-react'

import { classNames } from 'utils/react'

import state from 'state'

import { useTranslate, useLocalStore } from 'hooks'

import { Password as PasswordIcon } from 'components/shared/icons'

import './password.scss'

const block = 'set-password'
const cx = classNames(block)

const Password = () => {
  const Strings = useTranslate('shared.password')

  const form = useLocalStore(() => ({
    password: '',
    passwordConfirm: '',
    error: '',
    setPassword(e: React.ChangeEvent<HTMLInputElement>) {
      form.password = e.target.value
    },
    setPasswordConfirm(e: React.ChangeEvent<HTMLInputElement>) {
      form.passwordConfirm = e.target.value
    },
    async submit(e: React.FormEvent<HTMLFormElement>) {
      e.preventDefault()

      if (form.password.length < 6) {
        form.error = 'password_too_short'
        return
      }

      if (form.password !== form.passwordConfirm) {
        form.error = 'password_mismatch'
        return
      }

      try {
        form.error = ''
        await state.session.activate(form.password)
      } catch (err) {
        form.error = err.message
      }
    },
  }))

  return (
    <div className={cx(block)}>
      <PasswordIcon className={cx('__logo')} />
      <h1 className={cx('__title')}>
        {Strings.title}
      </h1>

      <h2 className={cx('__description')}>
        {Strings.description}
      </h2>

      <form className={cx('__form')} onSubmit={form.submit}>
        <input
          className={cx('__email', '__input')}
          type="password"
          placeholder={Strings.form.password.placeholder}
          value={form.password}
          onChange={form.setPassword}
        />
        <input
          className={cx('__password', '__input')}
          type="password"
          placeholder={Strings.form.passwordConfirm.placeholder}
          value={form.passwordConfirm}
          onChange={form.setPasswordConfirm}
        />

        {
          form.error && (
            <p className={cx('__error')}>
              {Strings.errors[form.error]}
            </p>
          )
        }

        <button className={cx('__button', '__submit')} type="submit">
          {Strings.form.submit}
        </button>
      </form>
    </div>
  )
}

export default observer(Password)
