import React from 'react'

import { cx } from '../utils'

export const BlockListUnordered = ({ attributes, children } : BlockProps) => (
  <ul className={cx('__list', '__list--unordered')} {...attributes}>
    {children}
  </ul>
)

export const BlockListOrdered = ({ attributes, children } : BlockProps) => (
  <ol className={cx('__list', '__list--ordered')} {...attributes}>
    {children}
  </ol>
)

export const BlockListItem = ({ attributes, children } : BlockProps) => (
  <li className={cx('__list-item')} {...attributes}>
    {children}
  </li>
)
