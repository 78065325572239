export default function MarkHotkey(options) {
  const { mark, key } = options

  return {
    // eslint-disable-next-line consistent-return
    onKeyDown(event, editor, next) {
      // If it doesn't match our `key`, let other plugins handle it.
      if (!event.ctrlKey || event.key !== key) return next()

      // Prevent the default characters from being inserted.
      event.preventDefault()

      // Toggle the mark `type`.
      return editor.toggleMark(mark)
    },
  }
}
