// React
import React from 'react'
import { observer } from 'mobx-react'

// Utils
import { Omit } from 'utils/types'

// Hooks
import { useAlertState } from 'hooks'

// Components
import { Portal } from 'react-portal'
import SweetAlert from 'react-bootstrap-sweetalert'
import { SweetAlertProps } from 'react-bootstrap-sweetalert/dist/components/SweetAlert'

// CSS
import './alert.scss'

const Alert = ({ register, children, ...props } : Omit<SweetAlertProps, 'title'|'showConfirm'|'showCancel'|'show'> & {
  register: (o: Function, c: Function, t: Function) => void,
  children: React.ReactNode
}) => {
  const alert = useAlertState(register, false)

  return (
    <Portal>
      <SweetAlert show={alert.show} title="" showConfirm={false} showCancel={false} {...props}>
        {children}
      </SweetAlert>
    </Portal>
  )
}

export default observer(Alert)
