import React from 'react'

import { cx } from '../utils'

// eslint-disable-next-line import/prefer-default-export
export const BlockLink = ({
  node, attributes, children,
} : BlockProps) => (
  <a
    autoCorrect="false"
    spellCheck="false"
    className={cx('__link')}
    href={node.data.get ? node.data.get('href') : node.data.href}
    target="_blank"
    rel="noreferrer noopener"
    {...attributes}
  >
    {children}
  </a>
)
