export default function List() {
  return {
    onKeyDown(event, editor, next) {
      if (!editor.isList()) {
        return next()
      }

      const isEnterKey = event.key === 'Enter'
      const isTabKey = event.key === 'Tab'
      const isBackspaceKey = event.key === 'Backspace'


      if (!isEnterKey && !isTabKey && !isBackspaceKey) {
        return next()
      }

      const { empty, atStart } = editor.getFocusInfo()

      // Enter key should unwrap list after empty item
      if (isEnterKey) {
        event.preventDefault()

        if (empty) {
          // If the line is currently empty, decrease list level, except for last level (unwrap)
          if (editor.getListLevel()) {
            return editor.decreaseListLevel()
          }

          return editor.unwrapAll()
        }

        // Else, proceed with new list-item creation
        editor.insertBlock('list-item')
        return null
      }

      // Backspace key should unwrap list if pressed at the start
      if (isBackspaceKey) {
        if (atStart && editor.value.selection.isCollapsed) {
          // If the line is currently empty, decrease list level, except for last level (unwrap)
          if (editor.isListItem() && !editor.isFirstListItem()) {
            return editor.setBlocks('paragraph')
          }

          if (editor.getListLevel()) {
            return editor.decreaseListLevel()
          }

          return editor.unwrapAll()
        }

        // Else, proceed with new list-item creation
        return next()
      }

      // Tab key should increase/decrease the level of each list in the selection
      if (isTabKey) {
        event.preventDefault()

        // Handle increase
        if (!event.shiftKey) {
          return editor.increaseListLevel()
        }

        // Handle decrease
        return editor.decreaseListLevel()
      }

      return next()
    },
    onKeyUp(event, editor, next) {
      editor.mergeLists()
      return next()
    },

    queries: {
      isList(editor) {
        return editor.isBlockActive('list-ordered') || editor.isBlockActive('list-unordered')
      },
      isListItem(editor) {
        return editor.isBlockActive('list-item')
      },
      isFirstListItem(editor) {
        const listItem = editor.value.document.getDescendantsAtRange(editor.value.selection)
          .reverse()
          .find((n) => n.type === 'list-item')

        if (!listItem) {
          return false
        }

        const previousSibling = editor.value.document.getPreviousSibling(listItem.key)

        return !previousSibling
      },
      getListLevel(editor) {
        let listLevel = 0
        editor.value.document.getDescendantsAtRange(editor.value.selection)
          .forEach((n) => {
            if (n.type === 'list-item' || n.type === 'paragraph') {
              const parents = editor.value.document.getAncestors(n.key)
              if (parents.size > listLevel) {
                listLevel = parents.size
              }
            }
          })

        // Subtract 2 : document/first-list
        return listLevel - 2
      },
    },

    commands: {
      increaseListLevel(editor) {
        editor.value.document.getDescendantsAtRange(editor.value.selection)
          .forEach((n) => {
            if (n.type === 'list-item' || n.type === 'paragraph') {
              const parents = editor.value.document.getAncestors(n.key)
              const parent = parents.last()
              editor.wrapBlockByKey(n.key, parent.type)
            }
          })
      },
      decreaseListLevel(editor) {
        editor.value.document.getDescendantsAtRange(editor.value.selection)
          .forEach((n) => {
            if (n.type === 'list-item' || n.type === 'paragraph') {
              const parents = editor.value.document.getAncestors(n.key)
              const parent = parents.last()
              if (parents.size > 2) {
                editor.setNodeByKey(n.key, 'list-item')
                editor.unwrapBlockByKey(n.key, parent.type)
              }
            }
          })
      },
      toggleList(editor, listType) {
        const families = editor.getFamilies()

        // Find the last list in each family
        const lists = families.map((f) => f
          .reverse()
          .find((n) => ['list-ordered', 'list-unordered'].includes(n.type)))
          .filter((l) => !!l)

        // Convert all lists to listType
        lists.forEach((l) => {
          editor.setNodeByKey(l.key, listType)
        })

        // And finally we merge if necessary
        editor.mergeLists()
      },
      mergeLists(editor) {
        // Find contiguous same-type lists
        editor.value.document
          .filterDescendants((n) => ['list-ordered', 'list-unordered'].includes(n.type))
          .forEach((n) => {
            // Check if previous sibling is of same type
            const sibling = editor.value.document
              .getPreviousSibling(n.key)

            if (sibling && sibling.type === n.type) {
              editor.mergeNodeByKey(n.key)
            }
          })
      },
    },
  }
}
