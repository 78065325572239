// React
import React from 'react'

// Utils
import { sanitizePath, Location, RouterLink } from 'utils/router'

const isPartiallyActive = (className : string) => ({
  isPartiallyCurrent,
} : {
  isPartiallyCurrent: boolean,
}) => (isPartiallyCurrent
  ? { className: `${className} active` }
  : {})

const isActive = (className : string) => ({
  isCurrent,
} : {
  isCurrent: boolean,
}) => (isCurrent
  ? { className: `${className} active` }
  : {})

const Link = ({
  to, exact, lang, ...props
}: { to: string, exact?: boolean, lang?: string, [key: string]: any }) => (
  <Location>
    {({ location }) => {
      const { pathname } = location

      return (
        <RouterLink
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...props}
          to={sanitizePath({ to, lang, pathname })}
          getProps={exact ? isActive(props.className) : isPartiallyActive(props.className)}
        />
      )
    }}
  </Location>
)

export default Link
