import { getEventTransfer } from 'slate-react'

const linkRegex = /^(https?:\/\/www\.|https?:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gi

export default function Link() {
  return {
    onPaste(event, editor, next) {
      // Get transfer element
      const transfer = getEventTransfer(event)

      // If the transfered element was not a fragment, check if it was a link
      if (['html', 'text'].includes(transfer.type) && transfer.text.match(linkRegex)) {
        // Check if some text was selected
        const { collapsed } = editor.getFocusInfo()

        // Disable current link, if any
        if (editor.isLink()) {
          editor.removeLink()
        }

        // If some text was selected
        if (!collapsed) {
          return editor.wrapInLink(transfer.text)
        }

        // If no text was selected, insert the link as is, wrapped in a link inline
        return editor.insertLink(transfer.text)
      }

      return next()
    },

    queries: {
      isLink(editor) {
        return editor.isBlockActive('link')
      },
    },

    commands: {
      removeLink(editor) {
        editor.unwrapInline('link')
      },
      wrapInLink(editor, href) {
        editor.wrapInline({
          type: 'link',
          data: { href },
        })
      },
      insertLink(editor, href, text = href) {
        editor.insertInline({
          type: 'link',
          data: { href },
          nodes: [{ object: 'text', text }],
        })
      },
    },
  }
}
