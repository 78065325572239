import React from 'react'

import { Link } from 'react-scroll'
import ScrollableSection, { configureAnchors } from '@insane/react-update-url-on-scroll'

import { cx, extractSlug } from '../utils'

configureAnchors({
  keepLastAnchorHash: true,
  offset: 0,
  onSectionEnter: () => {
    const event = new CustomEvent('sectionchange', {})
    window.dispatchEvent(event)
  },
})

// eslint-disable-next-line import/prefer-default-export
export const BlockHeading = ({
  readOnly, node, attributes, children,
} : BlockProps) => {
  if (readOnly) {
    return (
      <Link to={`${extractSlug(node)}`} smooth duration={300}>
        <h2 autoCorrect="false" spellCheck="false" className={cx('__heading')} {...attributes}>
          {children}
        </h2>
        <ScrollableSection
          hash={extractSlug(node)}
          id={extractSlug(node)}
          className={cx('__heading--anchor')}
        >
          <div />
        </ScrollableSection>
      </Link>
    )
  }

  return (
    <h2 autoCorrect="false" spellCheck="false" className={cx('__heading', '__h2')} {...attributes}>
      {children}
    </h2>
  )
}


export const BlockSubHeading = ({
  readOnly, node, attributes, children,
} : BlockProps) => {
  if (readOnly) {
    return (
      <Link to={`${extractSlug(node)}`} smooth duration={300}>
        <h3 autoCorrect="false" spellCheck="false" className={cx('__subheading')} {...attributes}>
          {children}
          <ScrollableSection
            hash={extractSlug(node)}
            id={extractSlug(node)}
            className={cx('__subheading--anchor')}
          >
            <div />
          </ScrollableSection>
        </h3>
      </Link>
    )
  }

  return (
    <h3 autoCorrect="false" spellCheck="false" className={cx('__subheading', '__h2')} {...attributes}>
      {children}
    </h3>
  )
}
