import React from 'react'
import { observer } from 'mobx-react'

import { classNames } from 'utils/react'

import state from 'state'

import { useTranslate, useMemo, useCallback } from 'hooks'

import { Portal } from 'react-portal'
import Alert from 'react-bootstrap-sweetalert'

import './prompt.scss'


const block = 'prompt'
const cx = classNames(block)

const Prompt = () => {
  const Strings = useTranslate('shared.semantics.prompt')

  const handleInputChange = useCallback((e) => {
    state.prompt.value = e.target.value
  }, [])

  const Input = useMemo(() => {
    if (state.prompt.options) {
      return ({ value } : { value: string }) => (
        <select className={cx('__input')} onChange={handleInputChange} value={value}>
          {
            state.prompt.options?.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))
          }
        </select>
      )
    }
    return ({ value } : { value: string }) => (
      <input
        className={cx('__input')}
        type="text"
        value={value}
        onChange={handleInputChange}
      />
    )
  }, [state.prompt.options])

  return (
    <Portal>
      <Alert
        title=""
        onConfirm={() => null}
        show={state.prompt.show}
        showConfirm={false}
        showCancel={false}
      >
        <h1 className={cx('__title')}>{state.prompt.title}</h1>
        <span className={cx('__content')}>{state.prompt.content}</span>

        {
          state.prompt.isInput && (<Input value={state.prompt.value} />)
        }

        <div className={cx('__buttons')}>
          <button
            type="button"
            onClick={state.prompt.cancel}
            className={cx('__button', '__button--cancel')}
          >
            {state.prompt.isInput ? Strings.input.cancel : Strings.check.cancel}
          </button>
          <button
            type="button"
            onClick={state.prompt.confirm}
            className={cx('__button', '__button--confirm', { '__button--danger': state.prompt.isDanger })}
          >
            {state.prompt.isInput ? Strings.input.confirm : Strings.check.confirm}
          </button>
        </div>
      </Alert>
    </Portal>
  )
}

export default observer(Prompt)
