import React, {
  useCallback, useState, useRef, useEffect,
} from 'react'

import { Value } from 'slate'
import { Editor as SlateEditor } from 'slate-react'

import Blocks from './blocks'
import { BlockUnknown } from './blocks/unknown'

import {
  Utils, MarkHotkey, ToolbarCommands, QuoteBlock, List, Heading, Link, Image,
} from './plugins'

import { block, cx } from './utils'

const defaultInitialValue = ({
  document: {
    nodes: [
      {
        object: 'block',
        type: 'paragraph',
        nodes: [
          {
            object: 'text',
            text: '',
          },
        ],
      },
    ],
  },
})

const plugins = [
  Utils(),
  MarkHotkey({ key: 'b', mark: 'bold' }),
  MarkHotkey({ key: 'i', mark: 'italic' }),
  MarkHotkey({ key: 'u', mark: 'underline' }),
  QuoteBlock(),
  List(),
  Heading(),
  Link(),
  Image(),
]

const Editor = ({
  valueId,
  initialValue,
  onValueChange,
  placeholder,
  registerCommands,
  imagePicker,
  simple,
  locale,
  readOnly,
} : {
  valueId: string,
  initialValue?: any,
  onValueChange: Function,
  placeholder?: string,
  registerCommands?: Function,
  imagePicker: any,
  simple?: boolean,
  locale?: string,
  readOnly?: boolean,
}) => {
  const [value, setValue] = useState(() => Value.fromJSON(initialValue || defaultInitialValue))
  const editor = useRef()
  const onChange = useCallback(({ value: v }) => {
    setValue(v)
    onValueChange(v)

    if (registerCommands) {
      registerCommands(ToolbarCommands(editor.current))
    }
  }, [])

  useEffect(() => {
    setValue(Value.fromJSON(initialValue || defaultInitialValue))
  }, [valueId])

  const renderBlock = useCallback((p) => {
    const Block = (simple ? Blocks.paragraph : Blocks[p.node.type]) || BlockUnknown

    return (
      <Block {...p} imagePicker={imagePicker} locale={locale} />
    )
  }, [])

  const renderMark = useCallback(({ children, mark, attributes }) => (
    <span {...attributes} autoCorrect="false" spellCheck="false" className={cx('__text', `__text--${mark.type}`)}>
      {children}
    </span>
  ), [])

  return (
    <SlateEditor
      ref={editor}
      readOnly={readOnly}
      autoCorrect={false}
      spellCheck={false}
      className={cx(block)}
      value={value}
      onChange={onChange}
      renderBlock={renderBlock}
      renderInline={renderBlock}
      renderMark={renderMark}
      placeholder={placeholder}
      plugins={simple ? undefined : plugins}
      schema={{
        blocks: {
          image: {
            isVoid: true,
          },
        },
      }}
    />
  )
}

export default React.memo(Editor)
